<template>
  <div>
    <b-tab title="Logs" active>
                <b-table striped hover :items="logs"></b-table>
          </b-tab>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "endpoingLogs",
  data() {
    return {
      logs: [],
      project: null,
    };
  },
  props: {
    endpointId: Number,
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    api.validateToken().then(
      () => {},
      () => {
        this.$router.push({ path: "/login" });
      }
    );
    this.getEndpointLogs(this.project, this.endpointId);
  },
  methods: {
    getEndpointLogs(projectId, endpoinId) {
      api.getEndpointLogs(projectId, endpoinId).then((response) => {
        this.logs = response.responseData;
        if (this.logs.length > 0) {
          this.logs.forEach((element) => {
            element.timestamp = element.timestamp
              .replace("T", " ")
              .split(".")[0];
          });
        } else {
          const date = new Date();
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const staticLog = {
            timestamp: year + "-" + month + "-" + day,
            message: "No logs to show.",
          };
          this.logs.push(staticLog);
        }
      });
    },
  },
};
</script>

<style scoped>
.all {
  margin-left: 60px;
}
.wrapper {
  height: 400px;
  overflow-y: scroll;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.botton {
  width: 150px;
  height: 35px;
  background: #23438e;
  border-radius: 5px;
  margin-left: 60px;
}
.editbutton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
</style>
