export default class config {
    static STATUS = {
        0: "Unknown",
        1: "Not Started",
        2: "Working",
        3: "Error",
        4: "Success",
        5: "Stop",
        6: "Pending",
        7: "Queued",
        8: "Stopping",
        9: "AuditQueued",
        10: "AuditWorking",
        11: "AuditError",
        12: "AuditSuccess",
        13: "Trial Success",
    }

    static BACKUPSTATUS = {
        0: "Unknown",
        1: "Not Started",
        2: "Backup Working",
        3: "Backup Error",
        4: "Backup Success",
        5: "Stop",
        6: "Pending",
        7: "Backup Queued",
        8: "Stopping",
        12: "Restore Working",
        13: "Restore Error",
        14: "Restore Success", 
        17: "Restore Queued",
        
    }

}
